import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import languageHelper from '../utils/helpers/languageHelper';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import BackToTop from '../components/navigation/backToTop';

const PageMl = ({ data }) => {
  const page = data.pageLegale;
  const locales = data.locales;

  const languageTo = languageHelper.getLanguageTo(locales.nodes);

  const [isCookiesPage, setIsCookiesPage] = useState(false);

  useEffect(() => {
    if (page?.contentful_id == '54aHItWj7fHtzr1EMWpYNa') {
      setIsCookiesPage(true);
    }
  }, [page]);

  return (
    <Layout
      headerCssClass="bkg-color2"
      mainCssClass={'page page-ml'}
      languageTo={languageTo}
      isPagePolicy={isCookiesPage}
    >
      <SEO
        title={page.seo?.metaTitle}
        titleOnglet={page.seo?.title}
        description={page.seo?.metaDescription}
        imageLink={page.seo?.socialImage?.file?.url}
        pageLink={page.seo?.slug}
      />

      {/* HeroTitle */}
      <HeroTitle title={page.title} />

      {/* IF NOT PAGE PRIVACY POLICY */}
      {!isCookiesPage && (
        <section
          className="section content"
          dangerouslySetInnerHTML={{
            __html: page.content.childMarkdownRemark.html,
          }}
        />
      )}

      {/* IF PAGE PRIVACY POLICY */}
      {isCookiesPage && (
        <>
          <Helmet>
            <script
              src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
              type="text/javascript"
              charset="UTF-8"
              id="otprivacy-notice-script"
            >
              {`
              settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"
              `}
            </script>
            <script type="text/javascript" charset="UTF-8">{`
            setTimeout(() => {OneTrust.NoticeApi.Initialized.then(function () {OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/f17733e7-0998-4692-b564-0383ad87fd9a.json"]);});}, 200)
            `}</script>
          </Helmet>

          <section className="section content">
            <div
              id="otnotice-f17733e7-0998-4692-b564-0383ad87fd9a"
              className="otnotice"
            ></div>
          </section>
        </>
      )}

      {/* BackToTop */}
      <BackToTop />
    </Layout>
  );
};

export const query = graphql`
  query contentfulPageLegaleTemplate(
    $contentfulId: String!
    $language: String!
  ) {
    pageLegale: contentfulPageLegale(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $language }
    ) {
      id
      node_locale
      seo {
        metaTitle
        metaDescription
        socialImage {
          file {
            url
          }
        }
        title
        slug
      }
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      contentful_id
    }
    locales: allContentfulPageLegale(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        seo {
          slug
        }
      }
    }
  }
`;

export default injectIntl(PageMl);
